import React from "react";

import SEO from "../components/seo";
import BaseLayout from "../components/layout/baseLayout";
import Container from "../components/container";

const PrivacyPage = () => {
  return (
    <div className="bg-gray-100">
      <SEO title="Datenschutz" />
      <BaseLayout>
        <Container>
          <div className="text-4xl lg:text-4xl xl:text-5xl font-bold mt-12">Datenschutz</div>
          <div className="my-12">
            <p>Diese App wird von der ViSpecs GmbH, Kirchengasse 76, 2722 Weikersdorf am Steinfelde, (im Folgenden „ViSpecs“) betrieben. ViSpecs ist die Wahrung Ihrer Privatsphäre besonders wichtig. Deshalb beachtet ViSpecs die gesetzlichen Datenschutz-Bestimmungen und informiert Sie nachstehend, wie Ihre personenbezogenen Daten verarbeitet werden.</p>
            <div className="text-xl lg:text-xl xl:text-2xl mt-5 mb-2 font-bold">Welche Daten werden verarbeitet?</div>
            <p>ViSpecs verarbeitet nachfolgende personenbezogene Daten, die Sie an ViSpecs etwa durch die Ihre Angaben bei der Anmeldung oder beim Hochladen Ihres Brillenpasses übermitteln:</p>
            <div className="mx-10 mt-2 mb-3">
              <ul className="list-disc">
                <li>angegebener Name</li>
                <li>angegebenes Geschlecht</li>
                <li>angegebene Telefonnummer</li>
                <li>angegebenes Geburtsjahr</li>
                <li>Standortdaten (sofern aktiviert)</li>
                <li>Kamera- und Speicherdaten (sofern genutzt)</li>
                <li>Profilbild (sofern hochgeladen)</li>
                <li>Daten und Ablichtung des Brillenpasses (sofern hochgeladen)</li>
                <li>Ergebnisse der Optiker-Bedarfsanalyse (sofern erstellt)</li>
              </ul>
            </div>
            <p>Es besteht keine Verpflichtung, uns diese Daten vollständig, richtig oder überhaupt zur Verfügung zu stellen. Allerdings stehen Ihnen ohne diese Daten einige Funktionen der App nicht oder nur eingeschränkt zur Verfügung.</p>
            <div className="text-xl lg:text-xl xl:text-2xl mt-5 mb-2 font-bold">Zu welchen Zwecken werden die Daten verarbeitet?</div>
            <p>ViSpecs verarbeitet Ihre personenbezogenen Daten zu nachstehenden Zwecken:</p>
            <div className="mx-10 mt-2 mb-3">
              <ul className="list-disc">
                <li>um Ihnen das Suchen von Optikern zu erleichtern</li>
                <li>um den Kontakt zwischen Ihnen und Optikern herzustellen</li>
                <li>um Ihre Bedürfnisse in Bezug auf Optikerleistungen zu erheben</li>
                <li>um die Übermittlung der Daten Ihres Brillenpasses an Ihren Optiker zu vereinfachen</li>
                <li>um Ihnen den Optikerwechsel zu vereinfachen</li>
              </ul>
            </div>
            <div className="text-xl lg:text-xl xl:text-2xl mt-5 mb-2 font-bold">Auf welchen Rechtsgrundlagen erfolgt Verarbeitung?</div>
            <p>Die rechtliche Grundlage für die Verarbeitung Ihrer personenbezogenen Daten ist Ihre ausdrückliche Zustimmung (Art 6 Abs 1 lit a DSGVO).</p>
            <div className="text-xl lg:text-xl xl:text-2xl mt-5 mb-2 font-bold">An wen werden die Daten übermittelt?</div>
            <p>Zu den oben genannten Zwecken werden wir Ihre personenbezogenen Daten an folgende Empfänger übermitteln:</p>
            <div className="mx-10 mt-2 mb-3">
              <ul className="list-disc">
                <li>von uns eingesetzte IT-Dienstleister</li>
                <li>von Ihnen ausgewählte Optiker</li>
              </ul>
            </div>
            <div className="text-xl lg:text-xl xl:text-2xl mt-5 mb-2 font-bold">Wie lange werden die Daten gespeichert?</div>
            <p>Wir werden Ihre Daten grundsätzlich für die Dauer des Bestehens Ihres Nutzerkontos speichern. Eine längere Speicherung erfolgt nur, soweit rechtliche Verpflichtungen dies notwendig machen.</p>
            <div className="text-xl lg:text-xl xl:text-2xl mt-5 mb-2 font-bold">Welche Rechte bestehen im Zusammenhang mit personenbezogenen Daten?</div>
            <p>Sie sind unter anderem berechtigt, (1.) zu überprüfen, ob und welche personenbezogenen Daten wir über Sie gespeichert haben und Kopien dieser Daten zu erhalten, (2.) die Berichtigung, Ergänzung oder das Löschen Ihrer personenbezogenen Daten, die falsch sind oder nicht rechtskonform verarbeitet werden, zu verlangen, (3.) von uns zu verlangen, die Verarbeitung Ihrer personenbezogenen Daten einzuschränken, und (4.) unter bestimmten Umständen der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen oder die für das Verarbeiten zuvor gegebene Einwilligung zu widerrufen, (5.) Datenübertragbarkeit zu verlangen, (6.) die Identität von Dritten, an welche Ihre personenbezogenen Daten übermittelt werden, zu kennen und (7.) bei der zuständigen Behörde Beschwerde zu erheben.</p>
            <div className="text-xl lg:text-xl xl:text-2xl mt-5 mb-2 font-bold">Wie kann man mit ViSepcs Kontakt aufnehmen?</div>
            <p>Sollten Sie zu der Verarbeitung Ihrer personenbezogenen Daten Fragen oder Anliegen haben, wenden Sie sich bitte an uns:</p>
            <div className="my-2">
              <b>
                <p>ViSpecs GmbH</p>
                <p>Kirchengasse 76</p>
                <p>2722 Weikersdorf am Steinfelde</p>
                <p>office@vispecs.com</p>
              </b>
            </div>
            <p>Alternativ können Sie sich auch gerne an unseren Datenschutzbeauftragten wenden:</p>
            <div className="my-2">
              <b>
                <p>Datenschutzbeauftragter der ViSpecs GmbH</p>
                <p>Kirchengasse 76</p>
                <p>2722 Weikersdorf am Steinfelde</p>
                <p>office@vispecs.com</p>
              </b>
            </div>
            <p className="text-sm text-gray-700 mt-2">Zuletzt aktualisiert am 2020-07-18.</p>
          </div>
        </Container>
      </BaseLayout>
    </div>
  );
}

export default PrivacyPage;